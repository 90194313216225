<template>
  <div>
    <el-card>
      <!-- 姓名 岗位 编号 工序  -->
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" @submit.native.prevent>
        <el-row>
          <el-col :md="8">
            <el-form-item label="员工姓名：">
              <el-input size="small" v-model="searchForm.stff_name" placeholder="员工姓名" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" class="vg_ml_16" @click="queryPro" icon="el-icon-search">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新 </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="vg_mtb_16">
          <el-col>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-plus" @click="addProc">新增</el-button>
            </el-form-item>
            <el-form-item class="vg_ml_10">
              <el-button type="danger" size="small" icon="el-icon-delete" @click="delProc">删除</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        class="vg_cursor"
        border
        :data="mupcList"
        style="width: 100%"
        @row-dblclick="dbClickJp"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label="序号" align="center" width="58"></el-table-column>
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="dept_name" label="所属部门"></el-table-column>
        <el-table-column prop="stff_no" label="员工编号"></el-table-column>
        <el-table-column prop="stff_name" label="员工姓名"></el-table-column>
        <el-table-column prop="user_name" label="账户名"></el-table-column>
        <el-table-column prop="mupc_names" label="工序" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mupc_defa" label="默认工序"></el-table-column>
        <el-table-column prop="stff_job" label="员工岗位">
          <template v-slot="scope">
            <span v-if="scope.row.stff_job">{{ scope.row.stff_job }}</span>
            <span v-else class="vg_9f9a9a">暂无岗位</span>
          </template>
        </el-table-column>
        <el-table-column prop="dept_team_name" label="小组名称">
          <template v-slot="scope">
            <span v-if="scope.row.dept_team_name">{{ scope.row.dept_team_name }}</span>
            <span v-else class="vg_9f9a9a">暂无小组</span>
          </template>
        </el-table-column>
      </el-table>
      <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
    </el-card>
  </div>
</template>
<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { mupcAPI } from '@api/modules/mupc';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';

export default {
  name: 'mupcList',
  data() {
    return {
      mupcList: [],
      searchForm: {
        page_no: 1
      },
      total: 0,
      multipleSelection: [],
      selectRefuDisabled: false, //所属部门 是否可选
      isDyj: true,
      beloDeptId: null
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mupc_list') {
        this.getProcList();
      }
    }
  },
  methods: {
    //判断是否 是DYJ员工
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if (userInfo.dept_id === 59 || userInfo.dept_id === 60) {
        this.searchForm.dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.searchForm.dept_id = null;
        this.isDyj = false;
      }
    },
    //获取列表数据
    getProcList() {
      getNoCatch(mupcAPI.getMupcs, this.searchForm).then(({ data }) => {
        this.mupcList = data.list;
        this.total = data.total;
      });
    },
    //分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getProcList();
    },
    //搜索查询
    queryPro() {
      this.searchForm.page_no = 1;
      this.getProcList();
    },
    buttonRefresh() {
      this.searchForm = {
        page_no: 1
      };
      this.currentPage = 1;
      this.getProcList();
      this.$refs.pubPagination.currentPage = 1;
    },
    //双击跳转
    dbClickJp(row) {
      this.jump(`/mupc_edit?id=${row.mupc_id}`);
    },
    //新增
    addProc() {
      this.jump('/mupc_add');
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delProc() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除该员工工序, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let mupc_id_list = this.multipleSelection.map(({ mupc_id }) => Object.values({ mupc_id }).toString());
          postNoCatch(mupcAPI.destroyMupcByIds, { mupc_id_list }).then(({ data }) => {
            this.getProcList();
          });
        });
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.dept_id = val;
    }
  },
  created() {
    this.getDepartment();
    this.getProcList();
    this.getDepartment();
  },
  components: {
    pubPagination,
    selectSectionType
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.vg_ml_32 {
  margin-left: 32px;
}

.vg_ml_10 {
  margin-left: 10px;
}

.vg_mtb_16 {
  margin: 16px 0;
}

.el-table {
  // margin-top: 15px;
}
</style>
